import React from "react";
import { PageHeader, Row, Col, Button, Card, List, Spin, Space, Typography, message, Popconfirm, Tag, Tooltip, Badge, Popover } from 'antd';
import axios from 'axios';
import { Link, useLocation, useNavigate, } from "react-router-dom";
import { User } from '../../../Hooks/Logged';
import CustomAvatar from '../../Widgets/Avatar/Avatar'
import { DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined, FilterOutlined, QuestionCircleOutlined, SwapOutlined } from "@ant-design/icons"
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import ModalInversion from "./ModalInversion";
import DrawerFiltros from "../../Widgets/DrawerFiltros/DrawerFiltros";
import Sort from "../../Widgets/Sort";

import useSearchParams from '../../../Hooks/SearchParams';

const moment = require('moment')
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Inversiones
 * @extends {React.Component}
 */
class Inversiones extends React.Component {

    static contextType = User

    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            inversiones: {
                data: [],

                page: page ?? 1,
                limit: limit ?? 10,

                total: 0,
                pages: 0,

                search: search ?? undefined,

                filters: JSON.tryParse(filters, []),
                sort: JSON.tryParse(sort, {}),

            },
            tempCantidades: {
                pendientes : 0,
                vendidas : 0,
                total : 0,
                revendidas : 0
            },
            loadingCant: false
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.getInversiones();
        console.log(this.props)
    }

    getCantidades = (inversion_id) => {
        this.setState({ loadingCant: true })
        axios.get('/inversion/cantidades', {
            params: {
                inversion_id
            }
        })
            .then(({ data }) => {
                console.log(data)
                this.setState({tempCantidades: data.data})
            })
            .catch(err => {

                message.error('No se pudieron cargar las cantidades.')
            })
            .finally(() => this.setState({ loadingCant: false }))

    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.getInversiones({ search: this.props.search })
        }
    }

    /**
    * @method getInversiones
    * @description Obtiene las inversiones realizadas
    */
    getInversiones = ({

        page = this.state.inversiones.page,
        limit = this.state.inversiones.limit,

        search = this.state.search,
        filters = this.state.inversiones.filters,
        sort = this.state.inversiones.sort,

    } = this.state.inversiones) => {

        const params = { page, limit, }
        if (search) params.search = search
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
        this.props.setParams(params)

        this.setState({ inversiones: { ...this.state.inversiones, page, limit, search, filters, sort }, loading: true })
        axios.get('/inversiones', {
            params: {
                page,
                limit,
                search,

                filters,
                sort
            }
        }).then(({ data }) => {

            this.setState({
                inversiones: {
                    ...this.state.inversiones,
                    ...data
                }
            })

        }).catch(error => {
            message.error("No fue posible obtener las inversiones")
        }).finally(() => this.setState({ loading: false }))
    }

    renderEstatusInversion = ({ estatus = 0 }) => {

        let steps = {
            0: <Tag className="tag-inversion-cancelada">CANCELADA</Tag>,
            1: <Tag className="tag-inversion-pendiente">PENDIENTE</Tag>,
            2: <Tag className="tag-inversion-pagada">PAGADA</Tag>,
            3: <Tag className="tag-inversion-ejecutada">EJECUTADA</Tag>,
            4: <Tag className="tag-inversion-reenvieda">REVENDIDA</Tag>,

        }

        return estatus != undefined ? steps[estatus] : 'N/A'

    }

    /**
 *
 *
 * @param {*} event
 * @param {*} filtro
 * @memberof Clientes
 * @description Renderizamos los filtros que estén en el arreglo.
 */
    renderFiltros = ({ filters } = this.state.inversiones) => {
        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.range?.join(' - ')}
                {filtro?.filter?.nombre || filtro?.filter?.label || filtro?.filter?.razon_social || filtro?.filter?.email || filtro?.filter?.folio}
            </Tag>
        });
    }


    /**
 *
 *
 * @param {*} key
 * @memberof Transacciones
 */
    setSort = (key) => {
        let value;
        switch (this.state.inversiones.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getInversiones({
            sort: {
                ...this.state.inversiones.sort,
                [key]: value
            }
        })
    }


    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        try {
            let { filters } = this.state.clientes
            let { _id, name } = filtro

            filters = filters.filter(f => {
                if (f.name === name) {
                    if (f.name === 'fecha') return false
                    return (f._id !== _id)
                }
                return true
            })

            this.setState({ clientes: { ...this.state.clientes, filters } }, () => this.getClientes())
        } catch (error) {
            console.log(error)
            this.setState({ visibleFilters: true })
        }
    }

    renderItem = item => (
        <Card className="card-list" >
            <Row className="width-100 " gutter={[0, 12]}>
                <Col className="center" xs={3} md={2}>
                    <Text>
                        {item.folio}
                    </Text>
                </Col>
                <Col className="center" xs={3} md={3}>
                    {(item.cantidad_revendidas > 0) ? <Popover
                        onOpenChange={(e) => e ? this.getCantidades(item._id) : this.setState({ tempCantidades: { total: 0, revendidas: 0, vendidas: 0, pendientes: 0 } })}
                        overlayStyle={{
                            boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
                        }}
                        content={
                            <Spin spinning={this.state.loadingCant}>
                                {this.state.tempCantidades.total || 0} plantas en total. <br />
                                {this.state.tempCantidades.revendidas || 0} para reventa. <br />
                                {this.state.tempCantidades.vendidas || 0} plantas vendidas. <br />
                                {this.state.tempCantidades.pendientes || 0} plantas pendientes <br />
                            </Spin>
                        }>
                        {item.cantidad - item.cantidad_revendidas} / {item.cantidad} planta{item.cantidad > 1 && 's'} <QuestionCircleOutlined style={{ fontSize: 16 }} />
                    </Popover> : <Text>
                        {item.cantidad} planta{item.cantidad > 1 && 's'}
                    </Text>}

                </Col>
                <Col xs={4} md={3}>
                    <Link to={`/admin/crm/clientes/detalle/${item.cliente_id?._id}`}>
                        <Space size={10}>
                            <CustomAvatar
                                name={item?.cliente_id?.nombre + " " + item?.cliente_id?.apellido_paterno + " " + item?.cliente_id?.apellido_materno}
                            />
                            <Text ellipsis>{item?.cliente_id?.nombre} {item?.cliente_id?.apellido_paterno} {item?.cliente_id?.apellido_materno}</Text>
                        </Space>
                    </Link>
                </Col>
                <Col className="center" xs={3} md={3}>
                    <Text>  {moment(item.fecha).format('DD-MM-YYYY')}</Text>
                </Col>
                <Col className="center" xs={3}>
                    <Text>$ {((typeof item.monto == "number") ? item.monto.toMoney(true) : "0.00")} {item.moneda}</Text>
                </Col>
                <Col className="center" xs={3}>
                    <Text>$ {((typeof item.monto_pendiente == "number") ? item.monto_pendiente.toMoney(true) : "0.00")} {item.moneda}</Text>
                </Col>

                <Col xs={3} md={3}>
                    <Space>
                        <CustomAvatar
                            image={item.hacienda_id?.imagenes?.length > 0 ? item.hacienda_id?.imagenes[0]?.filename : null}
                            name={item.hacienda_id?.nombre}
                        />
                        <Text ellipsis>{item.hacienda_id ? item.hacienda_id?.nombre : 'N/A'}</Text>
                    </Space>
                </Col>
                <Col span={3} className="center">
                    {this.renderEstatusInversion(item)}
                </Col>
                <Col className="center" xs={3} md={1}>
                    <Space direction="horizontal">
                        <Tooltip
                            title="Editar"
                            placement="topRight"
                        >
                            <Link to={item.estatus === 0 ? '#' : `/admin/crm/clientes/detalle/inversion/${item._id}`}>
                                <Button
                                    disabled={item.estatus === 0 ? true : false}
                                    size="small"
                                    type="primary"
                                    icon={<EyeOutlined />}
                                />
                            </Link>
                        </Tooltip>
                        {
                            true && (
                                <Tooltip
                                    title="Elimina"
                                    placement="topRight"
                                >
                                    <Popconfirm
                                        placement="topRight"
                                        title="¿Deseas eliminar esta inversión?"
                                        onConfirm={() => axios.delete('/inversion', {
                                            params: { id: item._id }
                                        }).then((response) => {
                                            message.success("Inversión eliminada")
                                        }).catch((error) => {
                                            message.error("Error al eliminar la inversión");
                                        }).finally(() => {
                                            this.getInversiones()
                                        })
                                        }
                                        okText="Sí"
                                        cancelText="No"
                                    >
                                        <Button size="small" type="primary" className='btn-delete' danger title="Eliminar" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </Tooltip>
                            )
                        }
                    </Space>
                </Col>
            </Row>
        </Card>
    )

    render() {

        return (
            <Spin spinning={false}>
                <PageHeader
                    className="admin-page-header"
                    title="Inversiones"
                    extra={<Space>
                        <Button icon={<FilterOutlined style={{ color: "currentcolor" }} onClick={() => this.setState({ visibleFilters: true })} />} />
                    </Space>}

                >{this.renderFiltros()}</PageHeader>
                <List
                    loading={this.state.loading}
                    className="component-list pd-1"
                    itemLayout="horizontal"
                    dataSource={this.state.inversiones.data}
                    locale={{ emptyText: "Sin Reventas" }}
                    pagination={{
                        current: this.state.inversiones.page,
                        pageSize: this.state.inversiones.limit,
                        total: this.state.inversiones.total,
                        pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                        position: 'bottom',
                        className: "flex-left ml-1",
                        onChange: (page, limit) => this.getInversiones({ page, limit })
                    }}
                    header={<Row className=" width-100 pl-1 pr-1 ">
                        <Col xs={3} md={2}>
                            <Sort
                                className="center"
                                sort={this.state.inversiones?.sort && this.state.inversiones?.sort['folio']} onClick={() => this.setSort("nombre")} >
                                Folio
                            </Sort>
                        </Col>
                        <Col xs={3} md={3}>
                            <Sort
                                className="center"
                                style={{ textAlign: 'center' }}
                                sort={this.state.inversiones?.sort && this.state.inversiones?.sort['cantidad']} onClick={() => this.setSort("nombre")} >
                                Plantas
                            </Sort>
                        </Col>
                        <Col xs={4} md={3}>
                            <Sort
                                className="center"
                                sort={this.state.inversiones?.sort && this.state.inversiones?.sort['cliente_id.nombre']} onClick={() => { this.setSort("cliente_id.nombre") }} >
                                Cliente
                            </Sort>
                        </Col>
                        <Col xs={3} md={3}>
                            <Sort
                                className="center"
                                sort={this.state.inversiones?.sort && this.state.inversiones?.sort['createdAt']} onClick={() => this.setSort("createdAt")} >
                                Fecha
                            </Sort>
                        </Col>
                        <Col xs={3} md={3} className="center">
                            Monto
                        </Col>
                        <Col className="center" xs={3} md={3}>
                            Monto Pendiente
                        </Col>
                        <Col xs={3} md={3} className="center">Hacienda</Col>
                        <Col xs={3} md={2}></Col>
                    </Row>
                    }
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Link to={`/admin/crm/clientes/detalle/inversion/${item._id}`} className="w-100">
                                {
                                    item.cantidad_revendidas ? (
                                        <Badge.Ribbon placement="start" text="Reventa">
                                            {this.renderItem(item)}
                                        </Badge.Ribbon>
                                    ) : this.renderItem(item)
                                }
                            </Link>
                        </List.Item>
                    )}
                />
                {/* <FloatingButton title="Nueva transacción" onClick={() => this.setState({ modalVisible: true, inversion_id: undefined })} /> */}
                {/* <ModalInversion
                    visible={this.state.modalVisible}
                    inversion_id={this.state.inversion_id}
                    onClose={() => {
                        this.setState({ modalVisible: false, inversion_id: undefined })
                        this.getInversiones()
                    }}
                /> */}


                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Inversiones"
                    csv="inversiones"
                    updateFilters={filters => this.getInversiones({
                        page: 1,
                        filters
                    })}
                    //Estatus de la inversion
                    //0 -> CANCELADA
                    //1 -> PENDIENTE 
                    //2 -> PAGADA
                    //3 -> EJECUTADA
                    //4 -> REVENDIDA.
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    estatus={{
                        type: 'list',
                        data: [
                            {
                                _id: 0,
                                label: "Cancelada"
                            },
                            {
                                _id: 1,
                                label: "Pendiente"
                            },
                            {
                                _id: 2,
                                label: "Pagada"
                            },
                            {
                                _id: 3,
                                label: "Ejecutada"
                            },
                            {
                                _id: 4,
                                label: "Revendida"
                            }
                        ]
                    }}
                    vendedores={true}
                    vendedores_transaccion={false}
                    haciendas={true}
                    cuentas={false}
                    clientes={true}
                    ordenes={false}
                    ordenes_compras={false}
                    area_rubro={false}
                    clasificadores={false}
                    razones_sociales={false}
                    negocios={false}
                    proveedores={false}
                    inversiones={false}
                />

            </Spin>
        )
    }
}

export default function (props) {

    const navigate = useNavigate()

    const [params, setParams] = useSearchParams();



    return <Inversiones {...props} navigate={navigate} setParams={setParams} params={params} />
}