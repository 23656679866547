import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FilePdfOutlined, SwapOutlined, EyeOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Space, Button, List, Typography, Card, message, Tooltip, Popconfirm, Collapse, Tag, Select, Popover, Modal } from "antd";

//componentes
import { renderEstatusReventa, renderEstatusInversion as estatusInversion } from '../../../Utils';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import FloatingButton from "../../../Widgets/FloatingButton/FloatingButton";
import { IconCategorias, IconMail, IconEye, IconListon, IconBtnEdit, IconChain, IconBtnDelete, IconMailOpen } from '../../../Widgets/Iconos';
//modales
// import ModalInversion from "../../Inversiones/ModalInversion"
import ModalReventa from "../../Reventas/ModalReventa"
import usePermissions from "../../../../Hooks/usePermissions";

import '../../../../Styles/Modules/Admin/Clientes/detalles.scss'

const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class Inversiones
 * @extends {Component}
 * @description Vista del listado de Inversiones realizadaspor el cliente
 */
class Reventas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            reventas: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                cliente_id: this.props.cliente_id
            },
            reventa_id: undefined,
            modal_visible: false,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (
            this.props.cliente_id ||
            this.props.inversion_id
        ) {
            this.getReventas()
        }
    }

    /**
    * @memberof getInversiones
    * @description Obtiene todas todas las inversiones del cliente
    * @param {object} {page, limit} Datos de paginado
    * @param {object} {inversiones} Array de inversiones
    */
    getReventas = ({

        page = this.state.reventas.page,
        limit = this.state.reventas.limit,

    } = this.state.reventas) => {
        this.setState({ loading: true })
        axios.get('/reventas', {
            params: {
                page,
                limit,
                cliente_id: this.props.cliente_id,
                inversion_id: this.props.inversion_id,
            }
        })
            .then(({ data }) => {
                console.log("data reventas", data)
                this.setState({
                    reventas: {
                        ...this.state.reventas,
                        ...data
                    }
                })

            })
            .catch(err => {

                message.error('No se pudieron cargar las reventas.')
            })
            .finally(() => this.setState({ loading: false }))

    }

    steps = [
        <Tag className="tag-inversion-cancelada">CANCELADA</Tag>,
        <Tag className="tag-inversion-pagada">EN PROCESO DE VENTA</Tag>,
        <Tag className="tag-inversion-ejecutada">VENDIDA</Tag>,
        <Tag className="tag-inversion-reenvieda">REQUIERE MODIFICACIÓN</Tag>,
    ]

    updateEstatus = (id, estatus) => {
        axios.put('/customer/reventa', {
            reventa_id: id,
            estatus
        }).then(() => {
            message.success("Estatus actualizado")
        }).catch(err => {
            message.error("Error al actualizar estatus")
        })
    }


    render() {
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list cliente-detalles-reventas"
                    itemLayout="horizontal"
                    dataSource={this.state.reventas.data}
                    locale={{ emptyText: "Sin Reventas" }}
                    pagination={{
                        current: this.state.reventas.page,
                        pageSize: this.state.reventas.limit,
                        total: this.state.reventas.total,
                        position: 'bottom',
                        className: "flex-left ml-1",
                        onChange: (page) => this.getInversiones({ page }),
                        hideOnSinglePage: true
                    }}
                    header={<Row gutter={[8, 0]} className=" width-100 pl-1 pr-1 ">

                        <Col xs={3} className="center">
                            <Text className="text-gray">Folio</Text>
                        </Col>
                        <Col xs={3} className="center">
                            <Text className="text-gray">Folio Inversion</Text>
                        </Col>
                        <Col xs={2} className="center">
                            <Text className="text-gray">Plantas</Text>
                        </Col>
                        <Col xs={3} className="center">
                            <Text className="text-gray">Precio</Text>
                        </Col>
                        <Col xs={4} className="center">
                            <Text className="text-gray">Hacienda</Text>
                        </Col>
                        <Col xs={2} className="center">
                            <Text className="text-gray">Fecha</Text>
                        </Col>
                        <Col xs={4} className="center">
                            <Text className="text-gray">Estatus</Text>
                        </Col>
                        <Col xs={3} className="center">
                            <Text className="text-gray">Acciones</Text>
                        </Col>
                    </Row>
                    }
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Collapse className="w-100 " ghost={true}>
                                <Collapse.Panel showArrow={false} header={<Card className="card-list hover" >
                                    <Row className="width-100 " gutter={[8, 8]}>
                                        <Col className="center" xs={3}>
                                            <Text>{item.folio}</Text>
                                        </Col>
                                        <Col className="center" xs={3}>
                                            <Text>{item.inversion_original_id?.folio ?? "N/A"}</Text>
                                        </Col>
                                        <Col className="center" xs={2}>
                                            <Popover
                                                overlayStyle={{
                                                    //boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
                                                }}
                                                content={
                                                    <>
                                                        {item.cantidad ?? 0} plantas en total. <br />
                                                        {item.cantidad_restante ?? 0} para reventa. <br />
                                                        {item.cantidad_vendida ?? 0} plantas vendidas. <br />
                                                    </>
                                                }>
                                                {item.cantidad_vendida} / {item.cantidad} planta{item.cantidad > 1 && 's'} <QuestionCircleOutlined style={{ fontSize: 16 }} />
                                            </Popover>
                                        </Col>
                                        <Col className="center" xs={3}>
                                            <Text>$ {item.precio_reventa?.toMoney ? item.precio_reventa.toMoney(true) : 0} {item.moneda}</Text>
                                        </Col>
                                        <Col className="center" xs={4}>
                                            <Space>
                                                <CustomAvatar
                                                    image={item.hacienda_id?.imagenes?.length > 0 ? item.hacienda_id?.imagenes[0]?.filename : null}
                                                    name={item.hacienda_id?.nombre}
                                                />
                                                <Text ellipsis>{item.hacienda_id ? item.hacienda_id?.nombre : 'N/A'}</Text>
                                            </Space>
                                        </Col>
                                        <Col className="center" xs={2}>
                                            <Text>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                        </Col>
                                        <Col className="center" xs={4} onClick={e => e.stopPropagation()}>
                                            <Select
                                                className="width-100 reventas-select"
                                                defaultValue={item.estatus}
                                                bordered={false}
                                                onChange={estatus => this.updateEstatus(item._id, estatus)}
                                            >
                                                {
                                                    this.steps.map((step, index) => <Select.Option key={"step-" + index} value={index}>
                                                        {step}
                                                    </Select.Option>)
                                                }
                                            </Select>
                                        </Col>
                                        <Col className="flex-right" xs={3} onClick={e => e.stopPropagation()}>
                                            <Space direction="horizontal">
                                                <Button
                                                    type="primary"
                                                    className='center ant-btn-primary-blue'
                                                    icon={<IconBtnEdit />}
                                                    disabled={!this.props.editar}
                                                    onClick={(e) => {
                                                        this.setState({ modal_visible: true, reventa_id: item._id })
                                                    }}
                                                />
                                                <Tooltip title="Editar Reventa">
                                                    <Button
                                                        danger
                                                        type="primary"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => {
                                                            Modal.confirm({
                                                                title: "¿Deseas eliminar la reventa",
                                                                onOk: () => {
                                                                    this.setState({ loading: true })
                                                                    return axios.delete('/reventa', {
                                                                        params: {
                                                                            id: item._id
                                                                        }
                                                                    })
                                                                        .then(e => {
                                                                            // this.getReventas()
                                                                            message.success("Reventa eliminada")
                                                                            this.props.getCliente()
                                                                        })
                                                                        .catch(error => {
                                                                            console.log(error)
                                                                            message.error("Error al eliminar la reventa");
                                                                        })
                                                                        .finally(() => this.setState({ loading: false }))
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Tooltip>
                                                {/* <Tooltip
                                                    title="Eliminar"
                                                    placement="topRight"
                                                >
                                                    <Popconfirm
                                                        placement="topRight"

                                                        title="¿Deseas eliminar esta inversión?"
                                                        onConfirm={() => axios.delete('/reventa', {
                                                            params: { id: item._id }
                                                        }).then((response) => {
                                                            message.success("Reventa eliminada")
                                                            this.props.getCliente()
                                                        }).catch((error) => {
                                                            console.log(error)
                                                            message.error("Error al eliminar la reventa");
                                                        }).finally(() => {
                                                            this.getReventas()
                                                        })
                                                        }
                                                        okText="Sí"
                                                        cancelText="No"
                                                        disabled={!this.props.delete || item.cantidad_vendida > 0 ? true : false}
                                                    >
                                                        <Button type="primary" className='center' danger title="Eliminar" icon={<IconBtnDelete />} />
                                                    </Popconfirm>
                                                </Tooltip> */}
                                            </Space>
                                        </Col>

                                    </Row>
                                </Card>}>
                                    <List
                                        loading={this.state.loading}
                                        className="component-list"
                                        itemLayout="horizontal"
                                        dataSource={item.inversiones_destino_id}
                                        locale={{ emptyText: "Sin Inversiones Destino" }}
                                        header={<Row className=" width-100 pl-1 pr-1 ">
                                            <Col xs={6} className="center">

                                                Folio

                                            </Col>
                                            <Col xs={5} className="center">
                                                Monto
                                            </Col>
                                            <Col xs={2} className="center">
                                                Cantidad
                                            </Col>
                                            <Col xs={3} className="center">
                                                Estatus
                                            </Col>
                                            <Col xs={4} className="center">
                                                Fecha
                                            </Col>

                                            <Col className="center" xs={3}>
                                                Precio por Planta
                                            </Col>
                                        </Row>
                                        }
                                        renderItem={childItem => (
                                            <List.Item className="component-list-item">
                                                <Card className="card-list" >
                                                    <Row className="width-100 " gutter={[0, 12]}>
                                                        <Col xs={6} className="center">
                                                            {childItem.folio}
                                                        </Col>
                                                        <Col xs={5} className="center">
                                                            $ {childItem.monto?.toMoney(true) ?? "0.00"} {childItem.moneda}
                                                        </Col>
                                                        <Col xs={2} className="center">
                                                            <Text>{childItem.cantidad} planta{item.cantidad > 1 && 's'}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center">
                                                            {estatusInversion(childItem)}
                                                        </Col>
                                                        <Col xs={4} className="center">
                                                            <Text>  {moment(childItem.fecha).format('DD-MM-YYYY')}</Text>
                                                        </Col>

                                                        <Col className="center" xs={3}>
                                                            $ {childItem.precio_planta?.toMoney(true) ?? "0.00"} {childItem.moneda}
                                                        </Col>

                                                        <Col xs={1} style={{ justifyContent: "end" }}>
                                                            <Space>
                                                                <Link to={`/admin/crm/clientes/detalle/inversion/${childItem._id}`} >
                                                                    <Tooltip title="Ver Inversion">
                                                                        <Button
                                                                            type="primary"
                                                                            icon={<EyeOutlined />}
                                                                        />
                                                                    </Tooltip>
                                                                </Link>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Card>

                                            </List.Item>
                                        )}
                                    />
                                </Collapse.Panel>
                            </Collapse>

                        </List.Item>
                    )}
                />
                {this.props.create && <FloatingButton title="Nueva Inversión" onClick={() => this.setState({ modal_visible: true, reventa_id: undefined })} />}
                <ModalReventa
                    visible={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, reventa_id: undefined })
                        if (flag === true) {
                            this.getReventas()
                            this.props.getCliente()
                        }
                    }}
                    cliente_id={this.props.cliente_id}
                    reventa_id={this.state.reventa_id}
                />
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function WrapperReventas(props) {


    return (<Reventas {...props}

        {...usePermissions(null, {
            editar: ["reventas", "edit"],
            delete: ["reventas", "delete"],
            create: ["reventas", "create"],
        })}
    />)
}