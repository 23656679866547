import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, message, Popconfirm, Space, Tooltip, Tag } from 'antd';
import { List, Avatar } from 'antd';
import ReactECharts from 'echarts-for-react';
import axios from 'axios'
import { Link, useHistory, useNavigate, useSearchParams } from "react-router-dom";
import "../../../Styles/Modules/dashboard.css";
import ModalInversionDetalle from "./ModalInversionDetalle";
import ModalPagoInversion from "../MiCuenta/ModalPagoInversion";
import { IconBtnDelete, IconBtnEdit, IconEye } from '../../Widgets/Iconos'
import { RightOutlined, DollarCircleOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require('moment')



/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {React.Component}
 * 
 * @description Resumen del Cliente
 */
class Resumen extends React.Component {

	constructor(props) {
		super(props)
		this.state = {

			invertido: {
				data: 0,
				loading: false
			},
			ganancia: {
				data: 0,
				loading: false
			},
			haciendas: {
				data: [],
				loading: false
			},
			proyecciones: {
				data: [],
				loading: false
			},

			loading: false,
			inversiones: {
				page: 1,
				total: 0,
				limit: 10,
				data: []
			},

			modal_visible: false,
			inversion_id: null

		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

		this.getDashboardTotalInvertido()
		this.getDashboardGanancia()
		this.getDashboardHaciendas()
		this.getProyeccion()
		this.getInversiones()

	}


	/**
	 *
	 *
	 * @memberof Dashboard
	 * @method getDashboardTotalInvertido
	 * @description Obtenemos el total invertido del cliente
	 */
	getDashboardTotalInvertido = () => {
		this.setState(state => {
			state.invertido.loading = true;
			return state;
		})

		return axios.get('/customer/dashboard/invertido')
			.then(({ data }) => {
				this.setState(state => {
					state.invertido.loading = true;
					state.invertido.data = data.data;
					return state;
				})
			})
			.catch(({ data }) => {

			})
	}

	/**
	 *
	 *
	 * @memberof Dashboard
	 * @method getDashboardGanancia
	 * @description Obtenemos el total ganado
	 */
	getDashboardGanancia = () => {
		this.setState(state => {
			state.ganancia.loading = true;
			return state;
		})
		return axios.get('/customer/dashboard/ganancia')
			.then(({ data }) => {
				const ganancia = {
					loading: false,
					data: data.data
				}
				this.setState({ ganancia })
			})
			.catch(({ data }) => {
			})
	}

	/**
	 *
	 *
	 * @memberof Dashboard
	 * @method getDashboardHaciendas 
	 * @description Obtenemos el total invertido por hacienda
	 */
	getDashboardHaciendas = () => {
		this.setState(state => {
			state.haciendas.loading = true;
			return state;
		})
		return axios.get('/customer/dashboard/haciendas')
			.then(({ data }) => {

				const haciendas = {
					data: data.data,
					loading: false
				}
				this.setState({ haciendas })

			})
			.catch(({ data }) => {

			})
	}


	/**
	 *
	 *
	 * @memberof Dashboard
	 * @method getProyeccion 
	 * @description Obtenemos las proyecciones
	 */
	getProyeccion = () => {
		this.setState(state => {
			state.proyecciones.loading = true;
			return state;
		})

		return axios.get('/customer/dashboard/proyecciones')
			.then(({ data }) => {

				const proyecciones = {
					data: data.data,
					loading: false
				}
				this.setState({ proyecciones })
			})
			.catch(({ data }) => {

			})
	}

	renderEstatusInversion = ({ estatus = 0 }, item) => {

		let steps = {
			0: <Tag className="tag-inversion-cancelada">CANCELADA</Tag>,
			1: <Button
				size="small"
				title="Pagar Inversión"
				type="primary"
				className='center'
				icon={<DollarCircleOutlined />}
				onClick={() => this.setState({ modal_visible_pago: true, inversion_id: item._id })}
				disabled={item.moneda !== "USD" || item.estatus === 2}
			>Pagar Ahora</Button>,
			2: <Tag className="tag-inversion-pagada">PAGADA</Tag>,
			3: <Tag className="tag-inversion-ejecutada">EJECUTADA</Tag>,
			4: <Tag className="tag-inversion-reenvieda">RENVIADA</Tag>,

		}

		return estatus != undefined ? steps[estatus] : 'N/A'

	}


	/**
	* @method getInversiones
	* @description Obtiene la lista de inversiones del cliente
	*/
	getInversiones = ({
		page = this.state.inversiones.page,
		limit = this.state.inversiones.limit,
	} = this.state.inversiones) => {

		this.setState({ loading: true })

		axios.get('/customer/inversiones', {
			params: {
				page: page,
				limit: limit,
			}
		}).then(response => {
			console.log("response", response.data.data);
			this.setState({
				inversiones: {
					...this.state.inversiones,
					data: response.data.data.data,
					page: response.data.data.page,
					limit: response.data.data.limit,
					total: response.data.data.total
				},
			})



		}).catch(error => {
			if (error.response.status === 403)
				message.error(error.response.data.message)
			else
				message.error('Error al cargar las transacciones')
		}).finally(() => this.setState({ loading: false }))
	}

	render() {

		return (
			<Content className="hm-layout-content layout-dashboard" >
				<PageHeader className="site-page-header" ghost={false} title="Resumen" />
				<Row gutter={[18, 18]}>
					<Col xs={24} md={12} xl={8}>
						<Row gutter={[18, 18]}>
							<Col xs={24}>
								<Card className="card-green" bordered={false} size="small" title={"Tus Inversiones"}>

									{(this.state.invertido?.data?.mxn > 0) ? <Text className="ml-1"> $ {this.state.invertido.data.mxn.toMoney(true)} MXN </Text> : null}
									{(this.state.invertido?.data?.usd > 0) ? <Text className="ml-1"> $ {this.state.invertido.data.usd.toMoney(true)} USD </Text> : null}
									<Link to="/customer/invertir" className="ml-1">
										<Button>
											Invertir Ahora
										</Button>
									</Link>
								</Card>
							</Col>
							{/* <Col xs={24}>
								<Card className="card-purple" bordered={false} size="small" title={"Tus Ganancias"}>
										<Text>$ {this.state.ganancia.data ? this.state.ganancia.data.toMoney(true) : 0} MXN</Text>
								</Card>
							</Col> */}
						</Row>
					</Col>

					<Col xs={24} md={12} xl={8}>
						<Card className="card-zeus" title={"Proyecciones"}>
							<ReactECharts
								option={{
									title: {
										text: ''
									},
									tooltip: {
										trigger: 'axis'
									},
									grid: {
										left: '3%',
										right: '4%',
										bottom: '3%',
										containLabel: true
									},
									xAxis: [
										{
											type: 'category',
											boundaryGap: false,
											data: ["Año 1", "Año 2", "Año 3", "Año 4"]
										}
									],
									yAxis: [
										{
											type: 'value'
										}
									],
									series: [{
										name: "Proyecciones",
										type: 'line',
										data: this.state.proyecciones.data.map(value => ({
											value
										})),

									}]
								}}
								style={{ height: 200, maxWidth: 400 }}
							/>
						</Card>
					</Col>

					<Col xs={24} xl={8}>
						<Card className="card-zeus" title={"Inversiones por Hacienda"}>
							<ReactECharts
								option={{
									title: {
										text: '',
									},
									tooltip: {
										trigger: 'item',
										formatter: "{a} <br/>{b} : {c} ({d}%)"
									},
									series: [{
										name: 'Ventas',
										type: 'pie',
										radius: '55%',
										center: ['50%', '60%'],
										data: this.state.haciendas.data.map(({ inversiones, color, nombre }) => ({
											value: inversiones,
											itemStyle: {
												color
											},
											name: nombre
										})),
										itemStyle: {
											emphasis: {
												shadowBlur: 10,
												shadowOffsetX: 0,
												shadowColor: 'rgba(0, 0, 0, 0.5)'
											}
										}
									}]
								}}
								style={{ height: 200 }}
							/>
						</Card>
					</Col>
				</Row>
				<Row gutter={[4, 8]}>
					<Title className="title-cyan zeus-title" level={3}>Mis Inversiones</Title>
				</Row>
				<List
					loading={this.state.loading}
					className="component-list"
					itemLayout="horizontal"
					dataSource={this.state.inversiones.data}
					locale={{ emptyText: "Sin inversiones " }}
					pagination={{
						current: this.state.inversiones.page,
						pageSize: this.state.inversiones.limit,
						total: this.state.inversiones.total,
						position: 'bottom',
						className: "flex-left",
						onChange: (page) => this.getInversiones({ page })
					}}
					renderItem={item => (
						<List.Item className="component-list-item">
							<Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
								<Row className="width-100 ">
									<Col span={1} className="center">
										<Avatar src="/img/zeus_logo_sin_texto.svg" />
									</Col>
									<Col className="center" xs={{ span: 18 }} md={{ span: 5 }}>
										<Space>
											<Text>Compra {item?.cantidad ? item?.cantidad : 0} Planta(s)</Text>
											{item.reventa_id && <Tag color="error">Reventa</Tag>}
										</Space>
									</Col>
									<Col className="center" xs={{ span: 20 }} md={{ span: 5 }}>
										<Text>{item.hacienda_id ? item.hacienda_id?.nombre : 'N/A'}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 3 }}>
										<Text>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 4 }}>
										<Text>{this.renderEstatusInversion({ estatus: item.estatus }, item)}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 4 }}>
										<Text>$ {item.monto.toMoney(true)} {item.moneda}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 2 }}>
										<Space>
											<Link to={`/customer/mi-cuenta/detalle/${item._id}`}>
												<Button
													type="primary"
													className='center'
													icon={<RightOutlined />}
												/>
											</Link>
										</Space>
									</Col>
								</Row>
							</Card>
						</List.Item>
					)}
				/>
				<ModalInversionDetalle
					visible={this.state.modal_visible}
					id={this.state.inversion_id}
					close={() => this.setState({ modal_visible: false, inversion_id: null })}
				/>
				<ModalPagoInversion
					visible={this.state.modal_visible_pago}
					onClose={() => {
						this.setState({ modal_visible_pago: false, inversion_id: undefined })
						this.getDashboardTotalInvertido()
						this.getDashboardGanancia()
						this.getDashboardHaciendas()
						this.getProyeccion()
						this.getInversiones()
					}}
					ficha={{ inversion: { _id: this.state.inversion_id } }}
				/>
			</Content>
		)
	}
}

export default props => {

	const navigate = useNavigate()
	const [params, setParams] = useSearchParams();

	return <Resumen {...props} navigate={navigate} params={params} />
}