import React, { Component, useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  AutoComplete,
  PageHeader,
  Layout,
  Select,
  message,
  Card,
  Input,
  Form,
} from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import EmailEditor from "../../Widgets/EmailEditor";

import "../../../Styles/Modules/Admin/Templates/Templates.scss";

const { Content } = Layout;
const { Option } = Select;
// / Supón que estos son tus usuarios
const variables = [
  {
    name: "Nombre del Cliente",
    value: "cliente_nombre",
  },
  {
    name: "Email del Cliente",
    value: "cliente_email",
  },
  {
    name: "Teléfono del Cliente",
    value: "cliente_telefono",
  },
  {
    name: "Estatus del Cliente",
    value: "cliente_estatus",
  },
  {
    name: "Nombre de la Hacienda",
    value: "hacienda_nombre",
  },
];

const MentionsInput = ({ value = "", onChange }) => {
  const [options, setOptions] = useState([]);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    const lastTagStart = internalValue.lastIndexOf("{{");
    const lastTagEnd = internalValue.lastIndexOf("}}");
    const searchValue =
      lastTagStart > lastTagEnd ? internalValue.slice(lastTagStart + 2) : "";

    if (lastTagStart !== -1 && lastTagEnd < lastTagStart) {
      setOptions(
        !searchValue
          ? variables.map((elem) => ({ value: elem.value }))
          : variables
              .filter((elem) =>
                (elem.value.toLowerCase() + elem.name.toLowerCase()).includes(
                  searchValue.toLowerCase()
                )
              )
              .map((elem) => ({ value: elem.value }))
      );
    }
  }, [internalValue]);

  const handleSelect = (data) => {
    const lastTagStart = internalValue.lastIndexOf("{{");
    const newValue = internalValue.slice(0, lastTagStart) + `{{${data}}}`;
    setInternalValue(newValue);
    setOptions([]);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleSearch = (data) => {
    setInternalValue(data);
    if (onChange) {
      onChange(data);
    }
  };

  return (
    <AutoComplete
      options={options}
      onSearch={handleSearch}
      onChange={handleSearch}
      onSelect={handleSelect}
      value={internalValue}
    >
      <Input.TextArea
        placeholder="Type {{username}}"
        autoSize={{ minRows: 1, maxRows: 5 }}
      />
    </AutoComplete>
  );
};

/**
 *
 *
 * @export
 * @class TemplateForm
 * @extends {Component}
 * @description Vista donde se listan todos los Templates
 */
class TemplateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email_template_id: this.props.params._id,
      haciendas: {
        data: [],

        page: 1,
        limit: 10,

        total: 0,
        pages: 0,
      },
    };
  }

  componentDidMount() {
    this.getHaciendas();
    if (this.state.email_template_id) this.get();
  }

  editorRef = React.createRef();

  get = () => {
    this.setState({ loading: true });
    return axios
      .get("/email-templates/" + this.state.email_template_id)
      .then(({ data }) => {
        this.formRef.setFieldsValue({
          ...data,
          hacienda_id: data?.hacienda_id?.map((e) => ({
            value: e._id,
            key: e._id,
            label: e.nombre,
          })),
        });
        this.editorRef.setContentHTML(data.contenido_html);
        this.editorRef.setImagesList(data.assets);
      })
      .catch((e) => {
        console.log("ASD", e);
      })
      .finally((e) => this.setState({ loading: false }));
  };

  getHaciendas = (
    {
      page = this.state.haciendas.page,
      limit = this.state.haciendas.limit,
      search = this.state.haciendas.search,
    } = this.state.haciendas
  ) => {
    this.setState({ loading: true });
    return axios
      .get("/haciendas", {
        params: {
          page,
          limit,
          search,
        },
      })
      .then(({ data }) => {
        data = data.data;
        this.setState({
          haciendas: data,
        });
      })
      .catch((e) => {
        console.log("ASD", e);
      })
      .finally((e) => this.setState({ loading: false }));
  };

  onFinish = (values) => {
    values.contenido_html = this.editorRef.getContentHTML();
    values.emails_templates_images_id = this.editorRef.getImagesList();
    values.hacienda_id = Array.isArray(values.hacienda_id)
      ? values.hacienda_id.map((proyecto) => proyecto?.value || proyecto)
      : [];
    if (this.props.params?._id) this.update(values);
    else this.add(values);
  };

  add = (values) => {
    this.setState({ loading: true });
    return axios
      .post("/email-templates", values)
      .then((e) => {
        message.success("Guardado correctamente");
        this.props.navigate(-1);
      })
      .catch(({ response, ...args }) => {
        if (response.data) message.error(response.data);
      })
      .finally(() => this.setState({ loading: false }));
  };

  update = (values) => {
    this.setState({ loading: true });
    axios
      .put("/email-templates/" + this.props.params?._id, values)
      .then((e) => {
        message.success("Guardado correctamente");
        this.props.navigate(-1);
      })
      .catch(({ response, ...args }) => {
        if (response.data) message.error(response.data);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <>
        <Spin spinning={this.state.loading}>
          <PageHeader className="admin-page-header" title="Nueva Plantilla" />
          <Content className="admin-content content-bg pd-1">
            <Card className="pd-2">
              <Form
                layout="vertical"
                ref={(ref) => (this.formRef = ref)}
                onFinish={this.onFinish}
              >
                <Row className="width-100" gutter={[16, 12]}>
                  <Col span={9}>
                    <Form.Item
                      name="nombre"
                      label="Nombre de la Plantilla"
                      rules={[
                        {
                          max: 255,
                          message: "No puede ser mayor a 255 caracteres",
                        },
                        {
                          required: true,
                          message: "Debe de indicar el nombre de la plantilla",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      name="asunto"
                      label="Asunto del Correo Electrónico"
                      rules={[
                        {
                          max: 255,
                          message: "No puede ser mayor a 255 caracteres",
                        },
                        {
                          required: true,
                          message: "Debe de indicar el nombre de la plantilla",
                        },
                      ]}
                    >
                      <MentionsInput />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="hacienda_id" label="Haciendas">
                      <Select
                        allowClear
                        showSearch
                        filterOption={false}
                        labelInValue
                        mode="multiple"
                        placeholder="Todos las haciendas"
                        onSearch={(search) =>
                          this.getHaciendas({ search, page: 1 })
                        }
                      >
                        {this.state.haciendas?.data.map((e) => (
                          <Option value={e._id} key={e._id}>
                            {e.nombre}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="descripcion"
                      label="Descripcion del Correo Electrónico"
                      rules={[
                        {
                          required: true,
                          message: "Debe de indicar el nombre de la plantilla",
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="contenido_plano"
                      label="Contenido del Correo Plano (Sin formato)"
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Contenido del Correo">
                      <EmailEditor
                        variables={variables || []}
                        ref={(e) => (this.editorRef = e)}
                        email_template_id={this.state.email_template_id}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} align="center">
                    <Button
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                      style={{ maxWidth: 400 }}
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Content>
        </Spin>
      </>
    );
  }
}

export default function TemplateFormWrapper(props) {
  return (
    <TemplateForm {...props} params={useParams()} navigate={useNavigate()} />
  );
}
