import React, { useContext } from "react";
import { Layout, Row, Col, Button, Typography, Card, InputNumber, Form, Statistic, List, message, Space, Avatar, Divider, Empty, Carousel as AntCarousel } from 'antd';
import ReactECharts from 'echarts-for-react';
import Header from './Header'
import Footer from './Footer'
import { IconAgabeColor, IconDolar, IconKilo, IconCalendar, IconInvest } from '../Widgets/Iconos'
import WOW from "wowjs";
import "../../Styles/Modules/landing.scss";
import { User } from "../../Hooks/Logged";
import TypeWriterEffect from "react-typewriter-effect";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";
import { FaTiktok, FaInstagram, FaFacebookF, FaGooglePlay, FaAppStore } from "react-icons/fa";
import VideoPlayer from "./VideoPlayer";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { CardHacienda } from "../Widgets/Cards";
import { MessageOutlined, WhatsAppOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const axios = require('axios')


/**
 *
 *
 * @export
 * @class Landing
 * @extends {React.Component}
 */
class Landing extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      cantidad_plantas: 0,
      precio_plantas: 0,
      precio_kilo: 0,
      inversion: 0,
      ganancia: 0,
      sub_ganancia: 0,
      comision: 0,
      proyecciones: [],
      precio_kilo_default: 0,

      //simlador
      hectareas: 0,
      solares: 0,
      total_invertido: 0,
      utilidad_bruta: 0,
      utilidad_neta: 0,
      utilidad_planta: 0,
      utilidad_mes: 0,
      utilidad_anio: 0,
      rendimiento_mes: 0,
      rendimiento_anio: 0,
      updateText: true,
      imagenes: [],
      //flags
      navbar: false,

      haciendas: {
        data: [],
        page: 1,
        limit: 10,
        total: 0
      }
    }
  }

  refInvestment = React.createRef();
  componentDidMount() {
    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

    new WOW.WOW({
      live: false
    }).init();

    this.getDefaultCotizacion();

    if (window !== undefined) {
      window.addEventListener("scroll", this.handleScrollAnimations)
    }

    this.getHaciendas()
    this.getImagenes()
  }


  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollAnimations)
  }


  handleScrollAnimations = (e) => {
    if (window.scrollY > 280 && !this.state.navbar) this.setState({ navbar: true })
    if (window.scrollY < 280 && this.state.navbar) this.setState({ navbar: false })
  }

  /**
   *
   *
   * @memberof Landing
   * @method setSimulateData
   * @description Establece los datos de la grafica
   */
  setSimulateData = () => {
    let data = this.state.proyecciones
    this.setState({
      option: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: ['Año 1', 'Año 2', 'Año 3', 'Año 4', 'Año 5', 'Año 6']
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
          name: 'Proyeccion',
          type: 'line',
          stack: '总量',
          data: data
        },
        ]
      }
    })
  }

  getHaciendas = ({
    page, limit
  } = this.state.haciendas) => {
    this.setState(state => {
      state.haciendas.loading = true
      return state
    })

    axios.get("/haciendas", {
      params: {
        page, limit
      }
    }).then(({ data }) => {
      this.setState(state => {
        state.haciendas = {
          ...data.data,
          page, limit
        }
        return state
      })
    }).catch(error => {
      console.log(error)
      message.error("Error al conseguir las haciendas")
    }).finally(() => {
      this.setState(state => {
        state.haciendas.loading = false
        return state
      })
    })
  }

  getImagenes = () => {
    this.setState(state => {
      state.haciendas.loading = true
      return state
    })

    axios.get("/haciendas/imagenes", {

    }).then(({ data }) => {
      console.log("data imagenes", data)
      this.setState(state => {
        state.imagenes = data.splice(0, 10)
        return state
      })
    }).catch(error => {
      console.log(error)
      message.error("Error al conseguir las imagenes")
    })
  }

  /**
   *
   *
   * @memberof Landing
   * @method getDefaultCotizacion
   * @description Obtener los valores de cotizacion
   */
  getDefaultCotizacion = () => {
    axios.get('/plantas')
      .then(res => {
        if (res.data.data != null) {
          let valores = this.refInvestment.current.getFieldsValue()

          let values = {
            kilos_planta: res.data.data.kgs_planta,
            precio_plantas_venta: res.data.data.precio_kilo,
            cantidad_plantas: 10
          }
          this.setState({ precio_kilo_default: res.data.data.precio_kilo })
          this.refInvestment.current.setFieldsValue(values)
          this.calculateInvestment({ ...valores, ...values })
          this.refInvestment.current.setFieldsValue(values)
        }

      })
      .catch(error => {
        console.log(error)
      })

  }





  /**
   *
   *
   * @memberof Landing
   * @method calculateInvestment
   * @param {object} values Valores del formulario de inversion
   * @description Calcula los datos de inversion
   */
  calculateInvestment = (values) => {

    /**
   * sim_plnata_compr 	= Numero plantas 		= cantidad_plantas
   * sim_precio_kilo 		= Precio por planta 	= precio_planta
   * sim_total_in 		= Total Invertido 		= total_invertido
   * 
   * sim_precio_kilo1 	= Precio por Kilo 		= precio_plantas_venta
   * sim_anio_plantada1 	= Años					= anios
   * sim_kilo_madre1 		= Kilos  por plantas 	= kilos_planta
   * */

    let { cantidad_plantas, precio_planta, total_invertido, precio_plantas_venta, anios, kilos_planta } = values;

    total_invertido = cantidad_plantas * precio_planta;
    let utilidad_bruta = anios <= 2 ? cantidad_plantas * precio_plantas_venta : cantidad_plantas * precio_plantas_venta * kilos_planta
    let utilidad_neta = anios === 6 ? utilidad_bruta - (utilidad_bruta / 100) * 35 : utilidad_bruta

    let utilidad_planta = utilidad_neta / cantidad_plantas
    let utilidad_mes = utilidad_neta / (12 * anios)
    let utilidad_anio = utilidad_neta / anios

    let rendimiento_mes = Math.round((utilidad_mes / total_invertido) * 100)
    let rendimiento_anio = Math.round((utilidad_anio / total_invertido) * 100)

    this.refInvestment.current.setFieldsValue({
      total_invertido
    })

    this.setState({
      hectareas: cantidad_plantas / 3990,
      solares: cantidad_plantas / 700,
      total_invertido,
      utilidad_bruta,
      utilidad_neta,
      utilidad_planta,
      utilidad_mes,
      utilidad_anio,
      rendimiento_mes,
      rendimiento_anio

    })

    //variables ????
    var a = [0.2, 0.5, 1.3, 0.3, 0.4, 0.75, 1],
      o = 0,
      i = 0,
      n = 0;
    let proyecciones = [];

    if (anios < 3) {
      o = (precio_plantas_venta - precio_planta) / anios
    } else {						//29 = precio plantas default 
      i = (precio_plantas_venta - this.state.precio_kilo_default) / (anios - 2)
    }

    if (o === 0) {
      n = cantidad_plantas * (precio_planta + precio_planta * a[0])
    } else {
      n = cantidad_plantas * (precio_planta + o / 2)
    }

    for (let l = 1; l <= anios; l++) {
      if (l < 3) {
        if (o === 0) {
          n = cantidad_plantas * (precio_planta + precio_planta * a[l])
        } else {
          n = cantidad_plantas * (precio_planta + o * l)
        }
      } else if (l === 3) {
        n = cantidad_plantas * this.state.precio_kilo_default * (kilos_planta * a[l])
      } else if (l > 3) {
        n = cantidad_plantas * (this.state.precio_kilo_default + i * (l - 2)) * (kilos_planta * a[l])
      }

      proyecciones.push(Math.round(100 * n) / 100)
    }
    if (values.precio_planta === undefined || values.cantidad_plantas === undefined || values.kilos_planta === undefined) return;


    this.setState({ proyecciones }, () => {
      this.setSimulateData()
    })

    /*
    let inversion = (Number(values.precio_planta) * Number(values.cantidad_plantas));
  	
    let sub_ganancia = parseFloat(values.kilos_planta) * parseFloat(values.precio_planta) * parseFloat(values.cantidad_plantas)
  	
    let comision = parseFloat(sub_ganancia * 0.35)
  	
    let ganancia = parseFloat(sub_ganancia - comision);
  	
    //proyeccion a 4 años
    for (let index = 1; index <= 4; index++) {
      let proyeccion = (ganancia * index);
      proyecciones.push(proyeccion)
    }
    */
  }

  reloadText = () => {
    this.state.updateText ? this.setState({ updateText: false }) : this.setState({ updateText: true })
  }


  ref = React.createRef()

  render() {
    window.x = this.ref
    console.log('this.ref', this.ref);
    return (
      <Layout  id="home" className="landing">
        <Header {...this.props} compact={this.state.navbar} updateText={this.reloadText}
          ref={e => {
            window.x = e
            this.ref = e
          }}
        />
        <AntCarousel autoplay effect="fade" className="carousel-bg">
          {
            this.state.imagenes.length === 0 ? <div>
              <img src="/img/agaves.png" alt="imagen" />
            </div> :
              [
                <div>
                  <img src="/img/agaves.png" alt="imagen" />
                </div>,
                ...this.state.imagenes?.map((item, index) =>
                  <div key={index}>
                    <img src={axios.defaults.baseURL + '/upload/' + (item?.filename ? item?.filename : 'default.png')} alt="imagen" />
                  </div>
                )
              ]
          }
        </AntCarousel>
        <Content
          className="main"
          style={{
            // backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%)',
          }}
        >
          <Row className="heigth-100 ">
            <Col xs={24} lg={12} className="col-text wow bounceInLeft">
              <Title level={1} strong className="text-white text-main">
                {t("landing.title")}
              </Title>
              <Paragraph className="text-white mb-3 text-secondary small">
                <TypeWriterEffect
                  textStyle={{ color: "#fff", fontSize: "24px" }}
                  startDelay={150}
                  width={200}
                  cursorColor="white"
                  text={t("landing.desc1")}
                  typeSpeed={60}
                  hideCursorAfterText={true}
                  key={this.state.updateText ? "1" : "2"}
                />
              </Paragraph>
              <Row>
                <Col>
                  <Button className="btn-google">
                    <a href="https://play.google.com/store/apps/details?id=com.zeus.agave&pli=1" target="_blank"><img src="/img/google-play.png" alt="GOOGLE" height="36" /></a>
                  </Button>
                </Col>
                <Col>
                  <Button className="btn-google">
                    <a href="https://apps.apple.com/us/app/zeus-agave/id1630001467" target="_blank"><img src="/img/apple-store.png" alt="App Store" height="36" /></a>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={10} className="center pt-2 wow bounceInRight  ">
              <div className="phone-content" style={{ position: "relative" }}>
                <img src={"/img/phone-temp.svg"} className="cell-img" />
                <div className="card-absolute dollar">
                  <img src="/img/zeus-logo-white.svg" style={{ width: "80%", display: "block", margin: "15px auto 0 auto", }} />
                </div>
                <div className="card-absolute agave">
                  <img src="/img/dollar.svg" style={{ width: "80%", display: "block", margin: "12px auto 0 auto", }} />
                </div>
                <Typography style={{ width: "100%", position: "absolute", top: 0, left: 0, }}>
                  <img src="/img/zeus-logo-call.svg" style={{ width: "40%", display: "block", margin: "12px auto 0 auto", marginTop: "30%", }} />
                  <Title style={{ textAlign: "center", marginTop: "1.5em", }} level={3}>¡Invierte en Agave!</Title>
                  <Title style={{ textAlign: "center", marginTop: "1.5em", }} level={4}>Baja nuestra App!</Title>
                  <Row align="middle" gutter={[10, 10]} style={{ position: "relative", margin: "auto", width: "100%", justifyContent: "center" }}>
                    <Col >
                      <a href="https://play.google.com/store/apps/details?id=com.zeus.agave&pli=1" target="_blank">
                        <Button icon={<FaGooglePlay style={{ fontSize: 14, position: "relative", top: 2, left: -3 }} />}>
                          Google Play
                        </Button>
                      </a>
                    </Col>
                    <Col>
                      <a href="https://apps.apple.com/us/app/zeus-agave/id1630001467" target="_blank">
                        <Button icon={<FaAppStore style={{ fontSize: 14, position: "relative", top: 2, left: -3 }} />}>
                          App Store
                        </Button></a>

                    </Col>
                  </Row>
                  <Title style={{ textAlign: "center", marginTop: "1.5em", }} level={4}>¡Contactanos!</Title>
                  {/* Josue Rosas (Gerente de Ventas)
                    josuerosas@zeusagave.com
                    USA +53 171 092 87 */}
                  <Row align="middle" gutter={[10, 10]} style={{ position: "relative", margin: "auto", width: "100%", justifyContent: "center" }}>
                    <Col >
                      <a target="_blank" href="tel:5317109287">
                        <Button size="large" type="link" icon={<WhatsAppOutlined style={{ color: "currentColor" }} />}>
                        +52 347 107 3340
                        </Button>
                      </a>
                    </Col>
                    <Col>
                      <a target="_blank" href="mailto:anadavalos@zeusagave.comm">
                        <Button size="large" type="link" icon={<MessageOutlined style={{ color: "currentColor" }} />}>
                        anadavalos@zeusagave.com
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </Typography>
                {/* <div className="content-url">
                  <a href="https://play.google.com/store/apps/details?id=com.zeus.agave&pli=1">
                    <Title level={4} style={{ minWidth: 200}}>¡Descarga nuestra App!</Title>
                   
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.zeus.agave&pli=1">
                    <Title level={4} style={{ minWidth: 200}}>¡Contactanos!</Title>
                  </a>
                </div> */}
              </div>

            </Col>
            <Col xs={24} lg={2} className="center mt-2 mb-1">
              <Row align="middle" gutter={[0, 32]}>
                <Col xs={8} lg={24} className="center">
                  <a href="https://www.instagram.com/zeus.agave/">
                    <FaInstagram size={35} fill="white" />
                  </a>
                </Col>
                <Col xs={8} lg={24} className="center">
                  <a href="https://www.facebook.com/ZeusElOroAzul/">
                    <FaFacebookF size={35} fill="white" />
                  </a>
                </Col>
                <Col xs={8} lg={24} className="center">
                  <a href="https://www.tiktok.com/@zeuseloroazul">
                    <FaTiktok size={35} fill="white" />
                  </a>
                </Col>
              </Row>

            </Col>
          </Row>
        </Content>
        <Row id={"about-us"}>
          <Col xs={24} xl={14} className="col-logos">
            <Row>
              {/* <Col xs={24} sm={12} lg={6} className="center pb-2">
                <img src="/img/dobel.svg" alt="Dobel" />
              </Col>
              <Col xs={24} sm={12} lg={6} className="center pb-2">
                <img src="/img/dragones.svg" alt="Dobel" />
              </Col>
              <Col xs={24} sm={12} lg={6} className="center pb-2">
                <img src="/img/loco.svg" alt="Dobel" />
              </Col>
              <Col xs={24} sm={12} lg={6} className="center pb-2">
                <img src="/img/jimador.svg" alt="Dobel" />
              </Col> */}
            </Row>
          </Col>
        </Row>
        <Row className=" section" style={{ marginTop: "100px", padding: "0 1.5rem" }} >
          <Col span={24} style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
            <Title  level={2} className="text-subtitle text-center">
              {t("landing.about.title")}
            </Title>
            <Divider />
            <Row justify="center" className="layout-img-text mt-1" gutter={[24, 24]}>
              <Col xs={22} lg={8} className="layout-img">
                <img src="/img/pato.png" className="w-100" />
              </Col>
              <Col xs={20} lg={10} className="layout-text">
                {t("landing.about.desc").map(p => (
                  <Paragraph className="">
                    {p}
                  </Paragraph>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "150px", padding: "0 1.5rem" }}>
          <Col span={24} style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
            <Title level={2} className="text-subtitle text-center animate__fadeIn">
              {t("landing.cta")}
            </Title>
            <Divider />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: "50px" }}>
          <Col span={20} style={{ maxWidth: '1200px' }}>
            <VideoPlayer cover="/img/promo.png">
              <source src="/vid/zeus-como-surge.mp4" type="video/mp4" />
            </VideoPlayer>
          </Col>
        </Row>



        <Row className=" section" style={{ marginTop: "150px", padding: "0 1.5rem" }}>
          <Col span={24} style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
            <Title level={2} className="text-subtitle text-center">
              {t("landing.start.title")}
            </Title>
            <Paragraph className="text-parrafo" style={{ maxWidth: 1000, fontSize: 16 }}>
              {t("landing.start.desc1")}<br />
              {t("landing.start.desc2")}
            </Paragraph>
          </Col>
        </Row>

        <Row className=" section" justify="center">
          <Col span={20}>
            <Row justify="center" className="mt-3" gutter={[16, 16]}>
              {t("landing.steps").map(({ title, desc, icon }, index) => <Col xs={24} md={12}>
                <Card
                  className="card-invest wow fadeInUp"
                  data-wow-delay="0.5s"
                  data-wow-duration=".35s"
                >
                  <Row className="w-100" wrap={false} gutter={[16, 16]}>
                    <Col flex="none">
                      <div className="card-logo">
                        <div className="logo-container">
                          <img src={icon} alt="icono" />
                        </div>
                      </div>
                    </Col>
                    <Col flex="auto">
                      <Title level={5} className="card-title">
                        {title}
                      </Title>
                      <Paragraph className="card-text">
                        {desc}
                      </Paragraph>
                    </Col>
                  </Row>
                </Card>
              </Col>)}
            </Row>
          </Col>
        </Row>


        <Row className=" section" justify="center" style={{ marginTop: "80px" }}>
          <Col span={20} style={{ maxWidth: '1200px' }}>
            <VideoPlayer cover="/img/land.png">
              <source src="/vid/zeus-spot-pato.mp4" type="video/mp4" />
            </VideoPlayer>
          </Col>
        </Row>

        <Row className=" section" style={{ marginTop: "150px", padding: "0 1.5rem" }}>
          <Col span={24} style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
            <Title level={2} className="text-subtitle text-center">
              {t("landing.grow.title")}
            </Title>
          </Col>
        </Row>

        <Row className=" section" justify="center">
          <Col xs={20} md={18}>
            <Row className="mt-3" gutter={[16, 16]}>
              {t("landing.grow.steps").map((desc, index) => <Col xs={24} md={{ span: 15, push: index * 3 }}>
                <Card
                  className="card-step wow fadeInUp"
                  data-wow-delay="0.5s"
                  data-wow-duration=".35s"
                >
                  <Row className="w-100" wrap={false} gutter={[16, 16]}>
                    <Col flex="none">
                      <div className="card-logo">
                        <div className="logo-container">
                          <p>{index + 1}</p>
                        </div>
                      </div>
                    </Col>
                    <Col flex="auto">
                      <Paragraph className="card-text">
                        {desc}
                      </Paragraph>
                    </Col>
                  </Row>
                </Card>
              </Col>)}
            </Row>
          </Col>
        </Row>

        <Row className=" section" justify="center" style={{ marginTop: "80px" }}>
          <Col span={20} style={{ maxWidth: '1200px' }}>
            <VideoPlayer cover="/img/land.png">
              <source src="/vid/zeus-inversion-crece.mp4" type="video/mp4" />
            </VideoPlayer>
          </Col>
        </Row>

        <Row className=" section" style={{ marginTop: "100px", padding: "0 1.5rem" }}>
          <Col span={24} className=" text-center">
            <Title level={2} className="text-subtitle">
              {t("landing.simulator.title")}
            </Title>
            <Paragraph className="text-parrafo">
              {t("landing.simulator.desc1")}
            </Paragraph>
          </Col>
          <Col span={24}>
            <Text className=" text-parrafo text-center center">
              {t("landing.simulator.disclaimer")}
            </Text>
          </Col>
        </Row>
        <Row className=" section" justify="center" >
          <Col span={20} style={{ maxWidth: '1200px', }} >
            <Form
              layout="vertical"
              className="width-100 mt-3 mb-3"
              requiredMark={false}
              ref={this.refInvestment}
              onFinish={this.calculateInvestment}
              initialValues={{
                cantidad_plantas: 1,
                precio_planta: 10,
                total_invertido: 1,
                precio_plantas_venta: 1,
                anios: 6,
                kilos_planta: 1,
              }}
              onValuesChange={(_, values) => {
                this.calculateInvestment(values);
              }}
            >
              <Row justify="center" gutter={[32, 32]}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="cantidad_plantas"
                    labelCol={24}
                    label={
                      <Text className="text-input">{t("landing.simulator.plants")}</Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.plants_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      prefix={<IconAgabeColor />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="precio_planta"
                    labelCol={24}
                    label={
                      <Text className="text-input">{t("landing.simulator.price")}</Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.price_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconDolar />}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="total_invertido"
                    labelCol={24}
                    label={<Text className="text-input">{t("landing.simulator.investment")}</Text>}
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.investment_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconInvest />}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="precio_plantas_venta"
                    labelCol={24}
                    label={
                      <Text className="text-input">{t("landing.simulator.price_kilo")}</Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.price_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconDolar />}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="anios"
                    labelCol={24}
                    label={<Text className="text-input">{t("landing.simulator.years")}</Text>}
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.years_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconCalendar />}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      min={0}
                      max={6}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="kilos_planta"
                    labelCol={24}
                    label={<Text className="text-input">{t("landing.simulator.weight")}</Text>}
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.weight_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconKilo />}
                      addonAfter={<Text>Kg</Text>}
                      formatter={(value) => {
                        setTimeout(() => {
                          return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        })
                      }

                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {/*Calculos*/}
        <Row style={{ width: '100%' }} align="center">
          <Col span={24} style={{ maxWidth: '1400px', }} >
            <Row justify="center" className="row-divider section">
              <Col span={20} style={{ maxWidth: '1200px', }} >
                <Text className="text-cyan-semi-dark">{t("landing.simulator.hectares")}: </Text>
                <Text>{this.state.hectareas.toMoney(true)}</Text>
              </Col>
              <Col span={20}>
                <Text className="text-cyan-semi-dark">{t("landing.simulator.plots")}: </Text>
                <Text>{this.state.solares.toMoney(true)}</Text>
              </Col>
            </Row>
            <Row justify="center" className="row-divider section">
              <Col xs={24} md={12}>
                <Text className="text-cyan-semi-dark simulador-text">
                  {t("landing.simulator.to_invest")}:
                </Text>
                <Text className="simulador-text">
                  $ {this.state.total_invertido.toMoney(true)}
                </Text>
              </Col>
              <Col xs={24} md={12}>
                <Text strong className="text-cyan-semi-dark simulador-text">
                  {t("landing.simulator.net_profit")}:
                </Text>
                <Text strong className="simulador-text">$ {this.state.utilidad_neta.toMoney(true)} <small>($ {this.state.utilidad_bruta} - 35%)</small></Text>
              </Col>
            </Row>
            <Row justify="center" className="row-divider section">
              <Col xs={24} md={12}>
                <Text strong className="text-cyan-semi-dark simulador-big-text">
                  {t("landing.simulator.gross_profit")}:
                </Text>
                <Text strong className="simulador-big-text">
                  $ {this.state.utilidad_bruta.toMoney(true)}
                </Text>
              </Col>
              <Col xs={24} md={12}>
                <Text className="text-cyan-semi-dark simulador-text">
                  {t("landing.simulator.profit_per_plant")}:
                </Text>
                <Text className="simulador-text">
                  $ {this.state.utilidad_planta.toMoney(true)}
                </Text>
              </Col>
            </Row>
            <Row justify="center" className="row-divider section">
              <Col xs={24} md={12}>
                <Text className="text-cyan-semi-dark simulador-text">
                  {t("landing.simulator.monthly_return")}:
                </Text>
                <Text className="simulador-text">
                  {this.state.rendimiento_mes.toMoney(true)} %
                </Text>
              </Col>
              <Col xs={24} md={12}>
                <Text className="text-cyan-semi-dark simulador-text">
                  {t("landing.simulator.monthly_profit")}:
                </Text>
                <Text className="simulador-text">
                  $ {this.state.utilidad_mes.toMoney(true)}
                </Text>
              </Col>
            </Row>
            <Row justify="center" className="row-divider section">
              <Col xs={24} md={12}>
                <Text className="text-cyan-semi-dark simulador-text">
                  {t("landing.simulator.yearly_return")}:
                </Text>
                <Text className="simulador-text">
                  {this.state.rendimiento_anio.toMoney(true)} %
                </Text>
              </Col>
              <Col xs={24} md={12}>
                <Text className="text-cyan-semi-dark simulador-text">
                  {t("landing.simulator.yearly_profit")}:
                </Text>
                <Text className="simulador-text">
                  {this.state.utilidad_anio.toMoney(true)}
                </Text>
              </Col>
            </Row>

            <Row className="section w-100" justify="center">
              <Col span={20}>
                <Row className="mt-3 mb-3" gutter={[16, 16]}>
                  <Col xs={24}>
                    {this.state.option ? (
                      <ReactECharts
                        option={this.state.option}
                        width="100%"
                        style={{ height: 500, width: "100%" }}
                      />
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section w-100" style={{ marginTop: "100px" }} id={"haciendas"}>
          <Col span={24} className=" text-center">
            <Title level={2} className="text-subtitle">
              {t("landing.hacienda.desc")}
            </Title>
            <Paragraph className="text-parrafo">
              {t("landing.hacienda.title")}
            </Paragraph>
          </Col>
        </Row>
        <Row justify={"center"} style={{ marginTop: "40px" }} className="section w-100">
          <Col xs={20} md={24} style={{ maxWidth: '1400px', width: "100%" }} >
            {
              this.state.haciendas.total < 1 ? (
                <Empty description="Sin Haciendas" />
              ) : (
                <Carousel
                  infinite
                  responsive={{
                    desktop_fhq: {
                      breakpoint: { max: 4000, min: 1500 },
                      items: 5
                    },
                    desktop_hq: {
                      breakpoint: { max: 1500, min: 1200 },
                      items: 4
                    },
                    desktop_xxl: {
                      breakpoint: { max: 1200, min: 992 },
                      items: 3
                    },
                    desktop_xl: {
                      breakpoint: { max: 992, min: 768 },
                      items: 3
                    },
                    desktop_lg: {
                      breakpoint: { max: 768, min: 576 },
                      items: 2
                    },
                    desktop_md: {
                      breakpoint: { max: 576, min: 464 },
                      items: 1
                    },
                    desktop_sm: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1
                    },

                    mobile: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1
                    }
                  }}>
                  {
                    this.state.haciendas.data.map(hacienda => <CardHacienda
                      item={hacienda}
                      color={hacienda.color}
                      content={<div style={{ color: "white" }}>
                        <div>
                          <span>Plantacion: </span>
                          <strong>{moment(hacienda.fecha_creacion).year()}</strong>
                        </div>
                        <div>
                          <span>Costo: </span>
                          <strong>{hacienda.precio_dolar?.toLocaleString("en-US", { style: "currency", currency: "USD" })} USD<br /> {hacienda.precio.toLocaleString("en-US", { style: "currency", currency: "USD" })} MXN</strong>
                        </div>
                      </div>}
                    onClick={() => {

                      this.ref.setState({modalAuth: true})
                    }}
                    >
                      {hacienda.ano_creacion}
                    </CardHacienda>
                    
                  )
                  }
                </Carousel>
              )
            }
            {/* <List
              itemLaoyout="horizontal haciendas"
              dataSource={this.state.haciendas.data}
              pagination={{
                current: this.state.haciendas.page,
                pageSize: this.state.haciendas.limit,
                total: this.state.haciendas.total,
                hideOnSinglePage: true,
                onChange: (page, limit) => this.getHaciendas({ page, limit }),
                align: "center"
              }}
              header={<Row className="w-100" align="middle">
                <Col xs={24} md={12} className="center">
                  <Text strong>{t("landing.hacienda.header1")}</Text>
                </Col>
                <Col xs={12} md={6} className="center">
                  <Text strong>{t("landing.hacienda.header2")}</Text>
                </Col>
                <Col xs={12} md={6} className="center">
                  <Text strong>{t("landing.hacienda.header3")}</Text>
                </Col>
              </Row>
              }
              renderItem={(item) => (
                <List.Item className="haciendas-item">
                  <Row className="w-100" align="middle">
                    <Col xs={24} md={12}>
                      <Space>
                        <Avatar style={{ backgroundColor: item.color }} />
                        <Text strong className="font-24">{item.nombre}</Text>
                      </Space>
                    </Col>
                    <Col xs={12} md={6} className="center">
                      <Text className="font-24">{moment(item.fecha_arrendacion).format("YYYY")}</Text>
                    </Col>
                    <Col xs={12} md={6} className="flex-right">
                      <Statistic
                        valueStyle={{ fontWeight: 600 }}
                        value={item.precio}
                        prefix={"$"}
                        precision={2}
                      />
                    </Col>
                  </Row>
                </List.Item>
              )}
            /> */}
          </Col>
        </Row>

        <Row style={{ marginTop: "100px" }}>
          <Col span={24} className=" text-center">
            <Title level={2} className="text-subtitle">
              {t("landing.hear_them.title")}
            </Title>
            <Paragraph className="text-parrafo">
              {t("landing.hear_them.desc1")}
            </Paragraph>
          </Col>
        </Row>
        <Row className="section" justify="center" >
          <Col span={20}>
            <Row justify="top" className="mt-3 mb-3" gutter={[24, 24]}>
              <Col xs={24} sm={24} lg={8} className="flex-column">
                <Card
                  className="width-100 wow fadeInUp"
                  data-wow-delay="0.5s"
                  data-wow-duration="0.5s"
                  cover={<img alt="example" src="/img/p1.jpg" />}
                >
                  {/* <Paragraph className="font-24 text-center">
                    {t("landing.hear_them.opinion.one")}
                  </Paragraph> */}
                </Card>
              </Col>
              <Col xs={24} sm={24} lg={8} className="flex-column">
                <Card
                  className="width-100 wow fadeInUp"
                  data-wow-delay="0.5s"
                  data-wow-duration="1s"
                  cover={<img alt="example" src="/img/p2.jpg" />}
                >
                  {/* <Paragraph className="font-24 text-center">
                    {t("landing.hear_them.opinion.two")}
                  </Paragraph> */}
                </Card>
              </Col>
              <Col xs={24} sm={24} lg={8} className="flex-column">
                <Card
                  className="width-100 wow fadeInUp"
                  data-wow-delay="0.5s"
                  data-wow-duration="1.5s"
                  cover={<img alt="example" src="/img/p3.jpg" />}
                >
                  {/* <Paragraph className="font-24 text-center">
                    {t("landing.hear_them.opinion.three")}
                  </Paragraph> */}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="section" justify="center" style={{ marginTop: "80px", marginBottom: "100px" }}>
          <Col span={20} style={{ maxWidth: '1200px', }} >
            <VideoPlayer cover="/img/land.png">
              <source src="/vid/zeus-spot-pato.mp4" type="video/mp4" />
            </VideoPlayer>
          </Col>
        </Row>
        <Footer />
      </Layout>
    );
  }
}


export default function View(props) {
  const { t, i18n } = useTranslation()
  const changeLanguage = (lng) => i18n.changeLanguage(lng)
  let user = useContext(User)




  // /email-:email/token-:token
  return <Landing user={user} changeLanguage={changeLanguage} t={t} {...props} />;
}
